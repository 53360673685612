import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import CaseStudyText from "../../screens/resources/case-study/sti-female-patient-hannah"

const CaseStudy = () => {
    return (
    <React.Fragment>
      <CaseStudyText />
    </React.Fragment>
  )
}

export default CaseStudy

export const Head = () => (
  <Seo
	title="Patient Case Study 1: Overtreatment of STIs"
	description="Hannah, an 18-year-old female college freshman, presents to a local clinic after experiencing a week of vaginal discharge and itching."
	image="/meta/case-study-hannah.jpg"
  />
)
